import * as React from "react"

import Seo from "../components/seo"


const NotFoundPage = () => (
  <>
    <Seo title="404: Not found" />
    <h1>404: Not Found</h1>
    <p>ページが見つかりませんでした。</p>
  </>
)

export default NotFoundPage